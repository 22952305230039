import React from "react";
import Section from "@livepix/components/layout/site/Section";
import Banner from "./Banner";
import FAQ from "./FAQ";
import { Button } from "@mui/material";

export default function Home() {
  return (
    <>
      <Banner />
      <Section
        maxWidth="lg"
        variant="columns"
        color="default"
        adornment="circle"
        title="Como funciona"
        titleAlignment="center"
        textAlignment="center"
        columns={[
          {
            id: "1",
            icon: "/images/home/config-icon.png",
            title: "Configuração",
            content: "Configure os widgets de alerta em seu software de transmissão ou use uma integração.",
          },
          {
            id: "2",
            icon: "/images/home/qrcode-icon.png",
            title: "Pagamento/Alerta",
            content: "Seus espectadores enviam pagamentos com mensagem e são exibidos em sua transmissão.",
          },
          {
            id: "3",
            icon: "/images/home/wallet-icon.png",
            title: "Carteira/Saque",
            content: "O dinheiro fica disponível na hora e você saca os valores a partir de R$ 1,00 via Pix 24/7.",
          },
        ]}
      />
      <Section variant="video" color="secondary" url="https://static.livepix.gg/videos/presentation.mp4" />
      <Section
        color="gray"
        variant="columns"
        titleAlignment="center"
        textAlignment="center"
        title="Integrações"
        maxWidth="md"
        subtitle="Receba os alertas nas ferramentas que você já usa"
        columns={[
          {
            id: "streamelements",
            icon: "/images/streamelements-icon.png",
            width: 100,
            height: 100,
            title: "StreamElements",
            content: "Informe suas credenciais e receba os alertas pelo StreamElements",
          },
          {
            id: "streamlabs",
            icon: "/images/streamlabs-icon.png",
            width: 120,
            height: 100,
            title: "StreamLabs",
            content: "Conecte sua conta e receba os alertas pelo StreamLabs",
          },
        ]}
      />
      <Section
        maxWidth="md"
        variant="feature"
        color="default"
        title="Mensagens"
        content="Receba mensagens via Pix ou Cartão de Crédito e exiba os alertas na sua transmissão."
        url="/images/illustrations/messages.svg"
        width={360}
        height={260}
        alt="Mensagens"
        alignment="left"
      />
      <Section
        maxWidth="md"
        variant="feature"
        color="default"
        title="Assinaturas"
        content="Receba assinaturas mensais via Pix ou Cartão de Crédito de seu público e conquiste uma renda recorrente."
        url="/images/illustrations/subscriptions.svg"
        width={360}
        height={250}
        alt="Assinaturas"
        alignment="right"
        actions={[
          <Button key="1" href="/ferramentas/assinaturas" variant="contained" size="large">
            Saiba mais
          </Button>,
        ]}
      />
      <Section
        maxWidth="md"
        variant="feature"
        color="default"
        title="Criptomoedas"
        content="Conecte sua carteira e receba alertas de pagamentos por criptomoedas através do nosso Smart Contract."
        url="/images/illustrations/crypto-2.svg"
        width={360}
        height={250}
        alt="Criptomoedas"
        alignment="left"
        actions={[
          <Button key="1" href="/ferramentas/crypto" variant="contained" size="large">
            Saiba mais
          </Button>,
        ]}
      />
      <Section
        maxWidth="md"
        variant="feature"
        color="default"
        title="Recompensas"
        content="Recompense seus apoiadores com acesso a grupos e conteúdo exclusivo."
        url="/images/illustrations/rewards-2.svg"
        width={360}
        height={350}
        alt="Recompensas"
        alignment="right"
        actions={[
          <Button key="1" href="/ferramentas/recompensas" variant="contained" size="large">
            Saiba mais
          </Button>,
        ]}
      />
      <Section
        maxWidth="xl"
        variant="columns"
        color="primary"
        title="Faça parte desta comunidade"
        subtitle="Junte-se aos milhares de criadores que já utilizam a LivePix!"
        columns={[
          {
            id: "partners",
            title: "Parceiros",
            heading: "+100 mil",
          },
          {
            id: "messages",
            title: "Mensagens recebidas",
            heading: "+10 milhões",
          },
          {
            id: "amount",
            title: "Valor transacionado",
            heading: "+100 milhões",
          },
        ]}
        actions={[
          <Button key="1" href="/abrir-conta" variant="contained" color="secondary" size="large">
            Abrir conta
          </Button>,
        ]}
      />
      <FAQ />
    </>
  );
}
